import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import Home from './pages/Home';
import Brands from './pages/Brands';
import Pricing from './pages/Pricing';
import PageNotFound from '../../common/pagenotfound/PageNotFound';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-201242941-1";
ReactGA.initialize(TRACKING_ID);

const Marketing = () => {
  let { path } = useRouteMatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path={path} component={() => <Home />} />
        <Route path='/brands' exact component={() => <Brands />} />
        <Route path='/pricing' exact component={() => <Pricing />} />
        <Route component={() => <PageNotFound />} />
      </Switch>
    </div>
  );
};

const MarketingModule = {
  routeProps: {
    path: '',
    exact: true,
    component: Marketing,
  },
  name: 'Marketing',
}

export default MarketingModule