import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Header from './common/header/header';
import Footer from './common/footer/footer';
import './assets/scss/style.scss';
import modules from './modules';
import WebFont from 'webfontloader';


WebFont.load({
  google: {
    families: ['Manrope:300,400,500,600,700,800', 'sans-serif']
  }
});

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {modules.map(module => (
          <Route {...module.routeProps} key={module.name} />
        ))}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
