import React from 'react';
import { Link } from 'react-router-dom';

export default function index(props) {
    return (
        <div className={`left-img-detail-section ${props.noBg? 'no-bg': ''}`}>
            <div className='row left-section'>
                <div className={`col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 ${props.data?.page==='brands' ? 'col-xxl-6':'col-xxl-7'}`}>
                    <img className='any-image' src={props.data.img} alt="imgleft" />
                </div>
                <div className={`col-11 col-sm-10 col-md-9 col-lg-5 col-xl-5 ${props.data?.page==='brands' ? 'col-xxl-5':'col-xxl-4'} ms-2 ms-xl-5`}>
                    <div className='row'>
                        <div className='col'>
                            { props.data.titleImg ?  <img className='shopiconimg' src={props.data.titleImg} alt="imgleft" /> : <h2 className='title'>{props.data.title} </h2> }
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='col'>
                            <p className='text'> {props.data.text}</p>
                        </div>
                    </div>
                    {props.data.button && 
                    <div className='row'>
                        <div className='col'>
                            <Link to="/brands">
                                <button className='clickbtnwhite'>{props.data.button}</button>
                            </Link>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
