import React, { useEffect, useState } from "react";
import './footer.scss';
import {headerSection, backgroundStyle} from '../../utils/staticStrings';
import { useLocation } from "react-router-dom";


export default function Footer() {
  const footerLocation = useLocation();
  const [ showFooter , setShowFooter ] = useState(true)

 
const renderFooter = () => {
  if( footerLocation.pathname.indexOf('/athlete') > -1 ||  footerLocation.pathname.indexOf("/purchaser") > -1) {
    setShowFooter(false);
  }else {
    setShowFooter(true);
  }
}

useEffect( () => {
  renderFooter();
  }, [footerLocation]);// eslint-disable-line react-hooks/exhaustive-deps

  
if (showFooter) {
  
  return (
    <div>
      {/*FOOTER */}
      <div className="container">
        <div className="row py-lg-5 px-3">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-10">
            <div className="text-left text-lg-left mb-3 logo-align">
              <img src={headerSection.headerLogo} alt="headerLogo" />
            </div>
          </div>

          {/* <div className="col-6 col-sm-5 col-md-4 col-lg-2 col-xl-2 mb-5 mt-lg-4 footer-text">
            <h5 className="title">Company</h5>
            <ul className="nav flex-column">
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">About us</a></li>
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">Contact</a></li>
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">Blog</a></li>
            </ul>
          </div> */}

          {/* <div className="col-6 col-sm-5 col-md-4 col-lg-2 col-xl-2 mb-5 mt-lg-4">
            <h5 className="title">Product</h5>
            <ul className="nav flex-column ">
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">Features</a></li>
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">FAQ</a></li>
              <li className="nav-item textual"><a href="https://stage.athleteup.com/" className="p-0 link-color">Support</a></li>
            </ul>
          </div> */}

          <div className="col mb-5 mt-lg-4 footer-content">
            <div className="row justify-content center footer-texts">
            <h5 className="title">Follow us</h5>
            <ul className="nav flex-column">
              <li className="nav-item textual"><img src={backgroundStyle.facebookIcon} alt="Facebook icon" width="17" height="17"/><a target='blank' href="https://www.facebook.com/AthleteUp1" className="p-0 link-color link-space">Facebook</a></li>
              <li className="nav-item textual"><img src={backgroundStyle.twitterIcon} alt="Twitter icon" width="18" height="16"/><a target='blank' href="https://twitter.com/AthleteUp_" className="p-0 link-color link-space">Twitter</a></li>
              <li className="nav-item textual"><img src={backgroundStyle.instaIcon} alt="Instagram icon" width="16" height="16"/><a target='blank' href="https://www.instagram.com/athleteup_/" className="p-0 link-color link-space">Instagram</a></li>
              <li className="nav-item textual"><a target='blank' href="mailto:info@athleteup.com?subject=SendMail&body=Description" className="p-0 link-color link-space link-mail">info@athleteup.com</a></li>
            </ul>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  )
} else {
  return (<div></div>)
}
}