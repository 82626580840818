import MarketingModule from './Marketing';
// import AthleteModule from './Athlete';
// import PurchaserModule from './Purchaser';

const modules = [
    MarketingModule,
    // AthleteModule,
    // PurchaserModule
]

export default modules