import { combineReducers } from "redux";

import PostReducer from "./posts/reducer";
import AthleteModuleReducer from "./athleteflow/reducer";

const rootReducer = combineReducers({
  PostReducer,
  AthleteModuleReducer
});

export default rootReducer;
