import React, { useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {CloseButton, Container} from 'react-bootstrap';
import {headerSection, backgroundStyle, interimLaunchUpdates} from '../../utils/staticStrings';
import './header.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from "react-router-dom";

const Header = () => {
  // const history = useHistory();
  const headerLocation = useLocation();
  const [ showHeader , setShowHeader ] = useState(true);
  const [navActive,  setnavActive ] = useState('home');
  const [ expanded, setExpanded ] = useState(false);

  const toggleMenu = () => {
    if(expanded)
      setExpanded(false);
    else 
      setExpanded(true);
  }

const renderHeader = () => {
  if( headerLocation.pathname.indexOf('/athlete') > -1) {
    setShowHeader(false);
  }else {
    setShowHeader(true);
  }
}

  useEffect( () => {
  renderHeader()
  }, [headerLocation]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // const redirectToRegisterPage = () => {
  //   history.push('/athlete/register')
  // };

  const higlightSelectedNav = (eventKey) => {
    setnavActive(eventKey);
    setExpanded(false);
  }


  if(showHeader) {
    return (
      <div className="header-style">
        <Navbar expand="lg" variant="dark" expanded={expanded} onToggle={toggleMenu}>
          <Container>
            <Navbar.Brand onClick={() => higlightSelectedNav('home')}>
              <Link to="/">
                <img
                  alt="headerlogo"
                  src={headerSection.headerLogo}
                  className="d-inline-block align-top"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" >{expanded ? <CloseButton variant='white'/> : <img src={backgroundStyle.customNav} alt="Menu"/>}</Navbar.Toggle>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end">
              <Nav onSelect={higlightSelectedNav} activeKey={navActive}>
              <Nav.Link className="nav-texts" eventKey='home' hidden>
                  <Link to="/">home</Link>
                </Nav.Link>
                {/* <Nav.Link className="nav-texts" eventKey={headerSection.headerNavText1}>
                  <Link to="/purchaser">{headerSection.headerNavText1}</Link>
                </Nav.Link> */}
                <Nav.Link as={NavLink} to="/brands" className="nav-texts" eventKey={headerSection.headerNavText2}>
                  {headerSection.headerNavText2}
                  <div className="highlight-link"></div>
                </Nav.Link>
                <Nav.Link as={NavLink} to="/pricing" className="nav-texts" eventKey={headerSection.headerNavText3}>
                  {headerSection.headerNavText3}
                  <div className="highlight-link"></div>
                </Nav.Link>

                {/* <Nav.Link className="nav-texts" eventKey={headerSection.headerNavText4}>
                  <a href="https://athleteup.com/blog/" target="_blank" rel="noreferrer" >{headerSection.headerNavText4}</a>
                </Nav.Link> */}

                <a role="button" className="nav-texts nav-link" href="https://blog.athleteup.com">
                  <a href="https://blog.athleteup.com/" target="_self" rel="noreferrer">BLOG</a>
                </a>

                {/* <Nav.Link className="log-in-text">
                <Link to="/athlete" className="log-in-link">{headerSection.headerNavText5}</Link>
                </Nav.Link> */}
              </Nav>
  
              {/* <button className="btn signbtn" onClick={redirectToRegisterPage}>SIGN UP</button> */}
              <button className="btn signbtn"> <a target='blank' href={interimLaunchUpdates.stayUpdateLink}> {interimLaunchUpdates.launchBtnLable}</a></button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );

  } else {
    return (
      <div className="header-style">
      <Navbar expand="lg">
          <Navbar.Brand className='ms-5'>  
            <Link to="/">
              <img
                alt="headerlogo"
                src={headerSection.headerLogo}
                className="d-inline-block align-top"
              />
            </Link>
          </Navbar.Brand>
      </Navbar>
    </div>
    )
  }
};

export default Header
