import axios from "axios";

//apply base url for axios

const axiosApi = axios.create({
  baseURL: "https://backend-stage.athleteup.com/api/v1/",
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) { 
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => {return response.data});
}

export async function post(url, config) {
  return await axiosApi
    .post(url, {
      ...config,
    })
    .then((response) => {
      // if (response && response.data && response.data.success) { 
        return response
      // }
    })
    .catch((error) => { 
      if (error.response && error.response.data && !error.response.data.success) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data ;
        // console.log('e1',error.response.data);
        // console.log('e2',error.response.status);
        // console.log('e3',error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log('e4',error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('e5', error.message);
      }
      // console.log('e6',error.config);
    })
}
