import React from "react";
import { meetOurStars, backgroundStyle } from "../../../../../utils/staticStrings";
import "../features.scss";

export default function index() {
  
  const renderStarCards = () => {
    return meetOurStars.starsLists.map((ele) => {
        return (
        <div className="col">
          <div className="card">
            <img src={ele.img} alt="elimg" />
            <div className="card-body">
              <h5 className="card-title">{ele.name}</h5>
              <p className="card-text sport-place">
                {ele.sport}
                {ele.place}
              </p>
              <p className="card-text"><img src={backgroundStyle.meetStarsListIcon} alt="starIcon"/>  <span className="listingsCount"> {ele.listingCount} listings</span></p>
            </div>
          </div>
        </div>
        )
    })
  };


  return (
    <div className="bgWhite">
      <div className="padding-main">
      <div className="sectionbg">
      <div className="meet-our-stars">

      <div className="row title">{meetOurStars.title}</div>
        <div className="row center texts mb-4">{meetOurStars.subtitle}</div>
        {/* generate dynamic cards here */}

        <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-2 athlete-grid">
            { renderStarCards() }
        </div>

        {/* <div className="row center">
          <div className="col">
            <button type="button" className="btn clickbtnblue">
              BROWSE ALL
            </button>
          </div>
        </div> */}
      </div>
      </div>
    </div>
    </div>
  );
}
