import './pagenotfound.scss';

const PageNotFound = () => {
    return (
        <div className='main-content'>
            <div className='padding-main'>
                <div className='text-center page-not-found'>
                    Sorry! Page Not Found
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;  