import React, { useEffect } from 'react';
import ImageButtonLinkSection from '../components/features/banner-details-section';
import HowItWorksSection from '../components/features/how-it-works';
import GetStartedSection from '../components/features/get-started';
import RightImgDetailSection from '../components/features/right-img-details-section';
import LeftImageDetailSection from '../components/features/left-img-details-section';
import WhyAthleteUpSection from '../components/features/why-athlete-up';
import Loader from 'react-loader-spinner';
import MaximizeEarningsSection from '../components/features/maximize-earnings';
import MeetOurStars from '../components/features/meet-our-stars';
import {
  runYourShopSection,
  likeAnAgentSection,
  atheleteUpDealsSection,
  atheleteUpShopSection,
  drivingGrowthSection,
  howItWorksSectionHome,
} from '../../../utils/staticStrings';

export default function Home() {
  const loadingPosts = false;

  const onClickLearnMore = () => {
    const id = 'how-it-works';
    const yOffset = -100; 
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-style main-content">
      {loadingPosts ? (
        <div className="loader">
          <Loader
            type="Bars"
            color="#00BFFF"
            height={50}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      ) : (
        <div>
          <div  className="padding-main">
          <ImageButtonLinkSection onLearnMoreClick={onClickLearnMore} showBrands={false} />
          </div>
          <div  className="padding-main">
          <HowItWorksSection data={howItWorksSectionHome} />
          </div>
          <div  className="padding-main">
          <LeftImageDetailSection data={atheleteUpShopSection} />
          </div>
          <div  className="padding-main">
          <RightImgDetailSection data={runYourShopSection} />
          </div>
          <div  className="padding-main">
          <LeftImageDetailSection data={atheleteUpDealsSection}/>
          </div>
          <div  className="padding-main">
          <RightImgDetailSection data={likeAnAgentSection} />
          </div>
          <MeetOurStars />
          <MaximizeEarningsSection />
          <div  className="padding-main">
          <WhyAthleteUpSection />
          </div>
          <div  className="padding-main">
          <LeftImageDetailSection data={drivingGrowthSection}/>
          </div>
          <GetStartedSection brand={false} />        
        </div>
      )}
    </div>
  );
}
