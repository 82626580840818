import { put, call, takeLatest } from 'redux-saga/effects';
import { LOGIN_ACTION, HANDLE_REGISTER, HANDLE_SPORTS_LISTS } from "./actionTypes";
import {hanldeError, hanldeLoginSuccess, handlerRegisterSuccess, handleSportsListsSuccess} from './action';
import {triggerLogin, triggerRegister, fetchSportsLists} from '../../modules/Athlete/helpers/apiController'

function* onLogin({payload:user}) {
    try {
      const response = yield call(triggerLogin, user);
      yield put(hanldeLoginSuccess(response.data.data));
    } catch (error) {
      yield put(hanldeError(error));
    }
}

function* onRegister(payload) {
  try {
    yield call(triggerRegister, payload.payload);
    yield put(handlerRegisterSuccess(true));

  } catch (error) {
    yield put(hanldeError(error));
    yield put(handlerRegisterSuccess(false));
  }
}

function* getSportsLists() {
  try {
    const response = yield call(fetchSportsLists);
    yield put(handleSportsListsSuccess(response.data.rows))
  } catch (error) {
    yield put(hanldeError(error));
  }
}


// Register here for all Api calls
function* AthleteModuleSaga() {
    yield takeLatest(LOGIN_ACTION, onLogin);
    yield takeLatest( HANDLE_REGISTER, onRegister);
    yield takeLatest(HANDLE_SPORTS_LISTS, getSportsLists);
}

  
export default AthleteModuleSaga;