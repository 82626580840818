import {post, get } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";


export const triggerLogin = (payload) =>{
 return post(url.USER_LOGIN, payload);
}

export const triggerRegister = (payload) => {
    return post(url.REGISTER, payload);
}

export const fetchSportsLists = () => {
    return get(url.GET_SPORTS_LIST)
}