import  { LOGIN_ACTION , HANDLE_ERROR, HANDLE_SUCCESS_RESPONSE, HANDLE_REGISTER, HANDLE_REGISTER_SUCCESS, HANDLE_SPORTS_LISTS, HANDLE_SPORTS_LISTS_SUCCESS} from './actionTypes';


export const userLogin = (user) => {
    return {
      type: LOGIN_ACTION,
      payload: user,
    };
  };

  export const hanldeError = (error) => {
    return {
      type: HANDLE_ERROR,
      payload: error,
    };
  };

  export const hanldeLoginSuccess = (data) => {
    return {
      type: HANDLE_SUCCESS_RESPONSE,
      payload : data
    }
  }

  export const submitRegister = (data) => {
    return {
      type: HANDLE_REGISTER,
      payload:data
    }
  }

  export const handlerRegisterSuccess = (data) => {
    return {
      type: HANDLE_REGISTER_SUCCESS,
      payload : data
    }
  }

  export const handleSportsLists = () => {
    return {
      type : HANDLE_SPORTS_LISTS,
    }
  }

  export const handleSportsListsSuccess = (data) => {
    return {
      type : HANDLE_SPORTS_LISTS_SUCCESS,
      payload : data
    }
  }
  