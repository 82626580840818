import React from 'react';
import '../features.scss';
import {
  bannerDetailsSection,
  brandsSection,
  backgroundStyle,
  interimLaunchUpdates,
  calendlyLink
} from '../../../../../utils/staticStrings';
import girlBannerImg from '../../../../../assets/images/bannerGirlImg.png';
import circleBg from '../../../../../assets/images/bannerEllipseImg.svg';
import { PopupWidget } from 'react-calendly';

export default function index(props) {
    // const onClickLearnMore = () => {
    //     props.onLearnMoreClick();
    // }

    return (
        props.showBrands ?
            <div className='brands-details'>
                <div className='row brand-row'>
                    <div className='col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6 banner-texts'>
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='main-heading'>{brandsSection.title}</div>
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-xxl-11'>
                                <div className='title'>{brandsSection.subtext}</div>
                            </div>
                        </div>
                        <div className='row btnrow'>
                            <div className='col-12 col-lg-6 col-xl-6'>
                                {/* <button className='clickbtnwhite' onClick={onClickLearnMore}> LEARN MORE</button> */}
                                <PopupWidget
                                    url={calendlyLink}
                                    /*
                                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                    */
                                    rootElement={document.getElementById("root")}
                                    text="BOOK A DEMO"
                                    textColor="#042237"
                                    color="#FFF"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-22 col-md-10 col-lg-6 col-xl-6 col-xxl-6 bg-ellipse'>
                        <img className="brand-image" src={brandsSection.img} alt="brand-imag" />
                        <img className="brand-image-mob" src={backgroundStyle.brandsBannerCardMob} alt="brand-imag" />
                    </div>
                </div>
                <div className='row banner-base'
                >
                    <div className='col-12 col-sm-8 col-md-10 col-lg-12 col-xl-9 col-xxl-8 bg-arrow'
                        style={{ background: `url('${backgroundStyle.bannerBaseLight}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
                    >
                        <div className='base-heading'>{brandsSection.basetitle}</div>
                        <div className='base-text'>{brandsSection.basetexts}</div>
                    </div>
                </div>
            </div>
            :
            <div className='banner-details'>
                <div className="row banner-row"
                >
                    <div className='col-12 col-sm-7 col-md-6 col-lg-10 col-xl-7 col-xxl-6 banner-texts'>
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='title'>{bannerDetailsSection.title}</div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-xxl-12'>
                                <div className='main-heading'>{bannerDetailsSection.heading}</div>
                            </div>
                        </div>
                        <div className='row btnrow mt-5'>
                            <div className='col clickbtnbluecontain'>
                                <button className='clickbtnblue'><a target='blank' href={interimLaunchUpdates.stayUpdateLink}> {interimLaunchUpdates.launchBtnLabelText2}</a></button>
                            </div>
                            <div className='col-10 col-md-5 col-lg-5 col-xl-6 col-xxl-5 book-demo'>
                                {/* <button className='clickbtnwhite' onClick={onClickLearnMore}> LEARN MORE</button> */}
                                 {/* <button className='clickbtnwhite' onClick={onClickLearnMore}> LEARN MORE</button> */}
                                 <PopupWidget
                                    url={calendlyLink}
                                    /*
                                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                    */
                                    rootElement={document.getElementById("root")}
                                    text="BOOK A DEMO"
                                    textColor="#042237"
                                    color="#FFF"
                                />
                            </div>

                        </div>
                    </div>
                    

                    <div className="col-12 col-sm-22 col-md-10 col-lg-12 col-xl-5 col-xxl-6 bg-ellipse">
          <img src={girlBannerImg} alt="Girl with basketball" className="girl-banner-img"/>
          <img src={circleBg} alt="" className="circle-banner-bg"/>
          <div className="bannerImg"></div>
        </div>
                </div>
                <div className="row banner-base">
        <div
          className="col-12 col-sm-8 col-md-10 col-lg-12 col-xl-9 col-xxl-8 bg-arrow">
          <div className="base-heading">{bannerDetailsSection.baseHeading}</div>
          <div className="base-text">{bannerDetailsSection.basetexts}</div>
        </div>
      </div>
            </div>
    )
}
