import { LOGIN_ACTION, HANDLE_ERROR , HANDLE_SUCCESS_RESPONSE, HANDLE_REGISTER_SUCCESS, HANDLE_SPORTS_LISTS_SUCCESS} from "./actionTypes";

const initialState = {
  isLoading: false,
  user: {
    "email": "sp25jan@yopmail.com",
    "password": "Test@123",
    status : false
  },
  error: {
    message: '',
  },
  registerSuccess : 'null',
  sportsListsArray : []
};

const AthleteModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      state = { ...state, user: action.payload, isLoading: true };
    break;
    case HANDLE_ERROR:
      state = {
        ...state,
        error: {
          message: action.payload,
        }
      };
      break;
    case HANDLE_SUCCESS_RESPONSE :
      state = {
        ...state,
        user: action.payload
      };
      break;
    case HANDLE_REGISTER_SUCCESS :
      state = {
        ...state,
        registerSuccess : action.payload
      };
      break;
    case HANDLE_SPORTS_LISTS_SUCCESS : 
      state = {
        ...state,
        sportsListsArray : action.payload
      };
      break;
      default:
        state = { ...state };
        break;
  }
  return state
};


export default AthleteModuleReducer;