import React, {useState, useEffect } from 'react';
import './pages.scss';
import {pricingSection, backgroundStyle, interimLaunchUpdates} from '../../../utils/staticStrings';
import { OverlayTrigger } from 'react-bootstrap';

export default function Pricing() {
  const [tab, setTab] = useState('Athlete');
  const [cardDetails, setCardDetails] = useState('');
  const [switchTab, setSwitchTab] = useState(true);
  const [screenWidth, setScreenWidth] = useState(1600);

  useEffect(()=> {
    window.scrollTo(0, 0);
    setScreenWidth(window.screen.width);
  }, []);

  useEffect(() => {
    tab === 'Athlete'
      ? setCardDetails(pricingSection.pricingSectionAthleteListCard)
      : setCardDetails(pricingSection.pricingSectionBrandsListCard);
  }, [cardDetails, tab]);

  const renderPricingLists = () => {
    const listArray =
      tab === 'Athlete'
        ? pricingSection.pricingSectionAthleteList
        : pricingSection.pricingSectionBrandsList;

    return listArray.map((ele) => {
      return (
        <div className="row liststext">
          <div
            className="bullet"
            style={{
              background: `url('${backgroundStyle.pricingCardTick}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'start',
            }}></div>
          <div className="col">{ele}</div>
        </div>
      );
    });
  };

  const pricingTooltip = (
    <div className='pricing-tooltip'>
      This fee is charged by our credit card processor (Stripe) at 2.9% +$0.30 per transaction.
      None of this fee, nor any other commission from your sales, goes to AthleteUp.
    </div>
  );

  return (
    <div className="container pricing-section main-content">
      <div className="row center title">{pricingSection.title}</div>

      <div className="row center headerText">
        <div className="col-sm-20 col-md-20 col-lg-10 col-xl-8 col-xxl-7 pricing-texts">
          <div className="row subtext1">{pricingSection.subtext1} </div>
          <div className="row subtext2">{pricingSection.subtext2} </div>
          <div className="row subtext3 center">{pricingSection.subtext3}</div>
        </div>
      </div>

      <div className="content">
        <div className="row center">
          <div className="col-sm-24 col-md-10 col-lg-12 col-xl-6 col-xxl-5">
            <div className="row center pad0">
              <div
                className={
                  tab === 'Athlete' ? 'col selectedTab' : 'col unselectedTitle '
                }
                onClick={() => setTab('Athlete')}>
                {pricingSection.pricingSectionBase.title1}
                <div
                  className={
                    tab === 'Athlete' ? 'col selectedTabC' : null
                  }></div>
              </div>
              <div
                className={
                  tab === 'Brand' ? 'col selectedTab' : 'col unselectedTitle '
                }
                onClick={() => setTab('Brand')}>
                {pricingSection.pricingSectionBase.title2}
                <div
                  className={tab === 'Brand' ? 'col selectedTabC' : null}></div>
              </div>
            </div>
            <div className="line"></div>
          </div>
        </div>

        <div className="row center">
          {tab === 'Athlete' && (
            <div className="row center pad0">
              <div className="col-sm-23 col-md-10 col-lg-10 col-xl-8 col-xxl-6">
                <div className="row center pad0">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      id="switch"
                      checked={switchTab}
                      onChange={() => setSwitchTab(!switchTab)}
                    />
                    <div className="app">
                      <div className="contentC">
                        <label htmlFor="switch">
                          <div className="toggle"></div>
                          <div className="names">
                            <p className={"light "+(!switchTab? 'current': '')} >Monthly</p>
                            <p className={"dark "+(switchTab? 'current': '')}>Yearly</p>
                          </div>
                        </label>
                        {switchTab && (
                          <div className="discountTab mb-4">
                            <div className="discountTabContent">
                              35% discount
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row center sammary-pad">
          <div className="col-sm-23 col-md-15 col-lg-8 col-xl-8 col-xxl-6">
            <div className="row center pad0 ">
              <div className="sammary">
                <div className="sammary-bg">
                  {tab === 'Athlete' && (
                    <div className="col justify-content-center">
                      <button type="button" class="btn btn-primary prbutton">
                        2 MONTHS FREE TRIAL
                      </button>
                    </div>
                  )}
                  <div className={"row strikeBotton justify-content-center mt-4 mb-4 " + (tab!=='Athlete'? 'brand-title': 'athlete-title')}>
                    <div className="col-9 start">
                      {tab === 'Athlete' ? (
                        <div className="pricingCard">
                          <span className="cardTitle col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                            {switchTab
                              ? cardDetails?.yearlySection1text1
                              : cardDetails?.monthlySection1text1}
                          </span><br className="mobile-break"/>
                          <span className="cardSubtitle col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                            {switchTab
                              ? cardDetails?.yearlySection1text2
                              : cardDetails?.monthlySection1text2}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="cardTitle">
                            {cardDetails?.section1text1}
                          </span>
                          {screenWidth > 992 ? <span className='cancelText'>{cardDetails?.section1text2}</span>: 
                            <div className='cancelText'>{cardDetails?.section1text2}</div>}
                        </div>
                      )}
                    </div>
                    <div className={"col-3 cardSpacing "+ (tab === 'Athlete'? 'mob-price':'')}>
                      <span className="cardMainText">
                        {tab === 'Athlete' ? (
                          <div className="row cardMainTextContent">
                            <div className="pricetext col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                              {switchTab
                                ? cardDetails?.yearlySection1text3
                                : cardDetails?.monthlySection1text3}
                            </div>
                            <div className="monthtext col-12 col-sm-7 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
                              {cardDetails?.section1text4}
                            </div>
                          </div>
                        ) : (
                          <span className="cardMainText">
                            {cardDetails?.section1text3}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='mob-border-bottom'></div>
                  </div>
                  {cardDetails?.section2text1 && (
                    <div className={"row pricingSub " + (tab!=='Athlete'? 'brand-pricing-sub':'')}>
                      <div className="col-9 start">
                        <span className="cardSubTexts">
                          {cardDetails?.section2text1}
                        </span>
                      </div>
                      <div className="col-3 cardSpacing">
                        <span className="cardMainText">
                          {cardDetails?.section2text2}
                        </span>
                      </div>
                    </div>
                  )}
                  {cardDetails?.section3text1 && (
                    <div className={"row strikeBotton "+ (tab!=='Athlete'?'':'brand-deals')}>
                      <div className="col-9 start">
                        <span className="cardSubTexts">
                          {cardDetails?.section3text1}
                        </span>
                      </div>
                      <div className="col-3 cardSpacing" >
                        <span className="cardMainText">
                          {cardDetails?.section3text2}
                        </span>
                      </div>
                      <div className='mob-border-bottom'></div>
                    </div>
                  )}
                  {cardDetails?.section4text1 && (
                    <div className="row center credit-card-text">
                      <div className="col-12">
                        <span>{cardDetails?.section4text1}</span>
                        <OverlayTrigger
                          trigger={['hover','focus']}
                          placement='bottom'
                          // delay={{ show: 250, hide: 400 }}
                          overlay={pricingTooltip}
                        >
                          <span className='price-img'>
                            <img src={cardDetails?.section4texticon} alt="priceimg"/>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row center pad0">
              <div className="cc">
                <div className="benefits">{renderPricingLists()}</div>
              </div>
            </div>
            <div className="row center pad0">
              <a target='blank' className='a-styled-button' href={interimLaunchUpdates.stayUpdateLink}><button className="styledBtn">{tab === 'Athlete'? interimLaunchUpdates.pricingSectionAthleteText: interimLaunchUpdates.pricingSectionBrandText}</button></a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
