import React from 'react';
import '../features.scss';
import {Link} from 'react-router-dom';
import { maximizeEarnings, interimLaunchUpdates } from '../../../../../utils/staticStrings';

export default function index() {
    return (
        <div className='maximize-earning-bg'>
        <div className='padding-main'>
        <div className='maximize-earnings'>
            <div className='bglight'>
                <div className='row title center'>{maximizeEarnings.title}</div>
                <div className='row center'>
                    <div className='col-xxl-7 subtext'>
                        {maximizeEarnings.text}
                    </div>
                </div>
                <div className='row center'><div className='col num-text'>{maximizeEarnings.percentage}</div></div>
                <div className='row title center fees-text'>{maximizeEarnings.feestext}</div>
                <div className='row center'>
                    <div className='col-sm-23 col-md-8 col-lg-7 col-xl-7 col-xxl-6'>
                        <div className='row center'>
                            <div className='col-xs-6 col-md-6 '><button className='btn clickbtnblue'> <a target='blank' href={interimLaunchUpdates.stayUpdateLink}> {interimLaunchUpdates.launchBtnLabelText}</a></button></div>
                            <div className='col-xs-6 col-md-6 '><Link to="/pricing"><button className='btn clickbtnwhite'>{maximizeEarnings.btnlabelsecond}</button></Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}