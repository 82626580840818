import React from "react";
import { Row } from "react-bootstrap";
import "../features.scss";

export default function index(props) {
  return (
    <div className="section-howit-works" id="how-it-works">


      <div className="row center">
        <div className="col heading">How it works</div>
      </div>

      <div className="row center ms-lg-5 me-lg-5">
        <div className="col-12 col-sm-7 col-md-6 col-lg-4 col-xl-3 col-xxl-3 cols">
          <Row className="imgRow">
            <img src={props.data.img1} width="60" height="50" alt="athlete1" />
          </Row>
          <Row className="center title title-text">{props.data.title1}</Row>
          <Row className="center texts">{props.data.text1}</Row>
        </div>
        <div class="col-12 col-sm-6 col-md-7 col-lg-5 col-xl-3 col-xxl-3 cols">
          <Row className="imgRow">
            <img src={props.data.img2} width="60" height="50" alt="athlete2" />
          </Row>
          <Row className="center title title-text">{props.data.title2}</Row>
          <Row className="center texts">{props.data.text2}</Row>
        </div>
        <div class="col-12 col-sm-7 col-md-7 col-lg-5 col-xl-3 col-xxl-3 cols ">
          <Row className="imgRow">
            <img src={props.data.img3} width="60" height="50" alt="athlete3" />
          </Row>
          <Row className="center title title-text">{props.data.title3}</Row>
          <Row className="center texts">{props.data.text3}</Row>
        </div>
        <div class="col-12 col-sm-6 col-md-7 col-lg-4 col-xl-3 col-xxl-3 cols">
          <Row className="imgRow">
            <img src={props.data.img4} width="60" height="50" alt="athlete4" />
          </Row>
          <Row className="center title title-text">{props.data.title4}</Row>
          <Row className="center texts">{props.data.text4}</Row>
        </div>
      </div>
    </div>
  );
}
