import React from "react";
import { PopupWidget } from "react-calendly";
import { calendlyLink, getStartedSection , interimLaunchUpdates} from "../../../../../utils/staticStrings";

export default function index(props) {
  return (

    !props.brand ?

      <div class="section-get-started center">
      <div class="row center gy-2 title">{getStartedSection.title}</div>
      <div class="row center texts">
        <div className='col col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-6'>
          {getStartedSection.text}
        </div>
      </div>
      <div class="row center gy-2">
        <div className="col">
          <button type="button" class="btn clickbtn">
          <a target='blank' href={interimLaunchUpdates.stayUpdateLink}> {interimLaunchUpdates.launchBtnLabelText}</a>
          </button>
        </div>
      </div>
    </div>

     :

     <div class="section-get-started center">
      <div class="row center gy-2 title">{getStartedSection.title}</div>
      {/* <div class="row center gy-2">
        <div className="col">
          <button type="button" class="btn clickbtn">
          <a target='blank' href={interimLaunchUpdates.stayUpdateLink}> {interimLaunchUpdates.launchBtnLabelText2}</a>
          </button>
        </div>
      </div> */}
      <div className="demo-contain">
        <PopupWidget
          url={calendlyLink}
          /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
          rootElement={document.getElementById("root")}
          text="BOOK A DEMO"
          textColor="#042237"
          color="#FFF"
          />
      </div>
    </div>
  );
}
